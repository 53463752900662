<template>
  <div id="user-list">
    <!-- app drawer -->
    <link rel="icon" href="./essilor.png" />
    <!-- list filters -->
    <v-card rounded="xl" :color="$vuetify.breakpoint.xsOnly ? 'white' : 'thead-color'" :disabled="loading">
      <v-card-title class="text-h6" :class="$vuetify.breakpoint.xsOnly ? 'thead-color' : 'form-color'">
        <v-icon left>
          {{ icons.mdiTableLarge }}
        </v-icon>
        Siparişler
      </v-card-title>

      <v-row no-gutters>
        <v-col cols="12" sm="4">
          <v-menu
            ref="menuStartDate"
            v-model="menuStartDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                class="rounded-0"
                label="Arama Başlangıç Tarihi"
                rounded
                readonly
                filled
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDateFormat" no-title @input="menuStartDate = false"></v-date-picker>
          </v-menu>
          <v-divider v-if="$vuetify.breakpoint.xsOnly" />
        </v-col>
        <v-col cols="12" sm="4">
          <v-menu
            ref="menuEndDate"
            v-model="menuEndDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                class="rounded-0"
                label="Arama Bitiş Tarihi"
                rounded
                readonly
                filled
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDateFormat" no-title @input="menuEndDate = false"></v-date-picker>
          </v-menu>
          <v-divider v-if="$vuetify.breakpoint.xsOnly" />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            class="rounded-0"
            label="Arama Terimi"
            placeholder="İsteğe Bağlı"
            persistent-placeholder
            rounded
            filled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-sheet color="primary" height="20">
            <v-sheet
              :color="$vuetify.breakpoint.xsOnly ? 'thead-color' : 'form-color'"
              rounded="b-xl"
              height="20"
            ></v-sheet>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <v-btn
            block
            color="primary"
            class="rounded-0 rounded-b-xl text-caption font-weight-bold"
            x-large
            @click.stop="submitForm"
          >
            <v-icon left>
              {{ icons.mdiMagnify }}
            </v-icon>
            Ara
          </v-btn>
        </v-col>
      </v-row>

      <!-- table -->

      <v-data-table
        :headers="tableColumns"
        sort-by="head.OrderNumber"
        :sort-desc="true"
        :search="search"
        :loading="loading"
        :items="tableItems"
      >
        <template v-slot:item.head.OrderNumber="{ item }">
          <v-chip label small>
            {{ item.head.OrderNumber }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn small depressed color="primary" outlined @click="detailFn(item.lines)"> Detay </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" max-width="1440">
      <v-card>
        <v-data-table
          :headers="headers"
          :items="tableDetailItems"
          disable-sort
          :items-per-page="-1"
          hide-default-footer
        >
          <template #item.Qty1="{ item }"> {{ item.Qty1 }} Adet </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axios from '@axios'
import {
  mdiAccountOutline,
  mdiCircle,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiMagnify,
  mdiTableLarge,
} from '@mdi/js'

export default {
  data() {
    return {
      dialog: false,
      tableItems: [],
      tableColumns: [
        { text: 'Sipariş No', value: 'head.OrderNumber', width: '175' },
        { text: 'Şipariş Tarihi', value: 'head.OrderDate', width: '140' },
        { text: 'Fatura No', value: 'head.InvoiceNumber', width: '140' },
        { text: 'E-Fatura No', value: 'head.EInvoiceNumber', width: '140' },
        { text: 'Açıklama', value: "'head.CurrAccDescription'", align: 'stat min-width-400' },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      search: '',
      loading: false,
      tableDetailItems: [],
      headers: [
        { text: 'Sipariş No', value: 'OrderNumber', width: '175' },

        { text: 'Ürün Adı', value: 'ItemDescription', align: 'stat min-width-300' },
        { text: 'Miktar', value: 'Qty1', width: '100' },
        { text: 'Cam Yönü', value: 'ITAtt02', width: '100' },
        { text: 'SPH', value: 'ProductAtt08Desc', width: '50' },
        { text: 'CYL', value: 'ProductAtt09Desc', width: '50' },
        { text: 'Axe', value: 'ITAtt07', width: '50' },
        { text: 'Kullanıcı Adı', value: 'LineDescription', width: '150' },
        { text: 'Faturanın Kesileceği Ofis Adı', value: 'FaturaninKesilecegiOfisAdi', width: '250' },
        { text: 'Sipariş Durumu', value: 'Siparis_Durumu', width: '140' },
        { text: 'Açıklama', value: 'Aciklama', width: '240' },
        { text: 'Sipariş Tarihi', value: 'Siparis_Tarihi', width: '140' },
        { text: 'Fatura Tarihi', value: 'Fatura_Tarihi', width: '140' },
        { text: 'Fatura Durumu', value: 'Fatura_Durumu', width: '140' },
      ],
      startDateFormat: new Date(Date.now() + new Date().getTimezoneOffset() * 60000 * 10 * 30)
        .toISOString()
        .substr(0, 10),
      startDate: this.formatDateStart(
        new Date(Date.now() + new Date().getTimezoneOffset() * 60000 * 10 * 30).toISOString().substr(0, 10),
      ),
      endDateFormat: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      endDate: this.formatDateEnd(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      ),
      menuStartDate: false,
      menuEndDate: false,
      icons: {
        mdiTableLarge,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiCircle,
        mdiMagnify,
        mdiAccountOutline,
      },
    }
  },

  watch: {
    startDateFormat(val) {
      this.startDate = this.formatDateStart(this.startDateFormat)
    },
    endDateFormat(val) {
      this.endDate = this.formatDateEnd(this.endDateFormat)
    },
  },
  mounted() {
    this.submitForm()
  },

  methods: {
    formatDateStart(startDateFormat) {
      if (!startDateFormat) return null

      const [year, month, day] = startDateFormat.split('-')

      return `${day}/${month}/${year}`
    },
    formatDateEnd(endDateFormat) {
      if (!endDateFormat) return null

      const [year, month, day] = endDateFormat.split('-')

      return `${day}/${month}/${year}`
    },
    detailFn(item) {
      this.tableDetailItems = item
      this.dialog = true
    },
    async submitForm() {
      this.loading = true
      const params = new URLSearchParams()
      params.append('method', 'getOrders')
      params.append('prdtype', 'lens')
      params.append('startDate', this.startDateFormat)
      params.append('endDate', this.endDateFormat)
      axios
        .post('', params)
        .then(response => {
          if (!response.data.error) {
            return
          }
          this.tableItems = response.data.detail
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
.v-data-table-header-mobile {
  display: none;
}
.theme--light.v-data-table th {
  border-top: none !important;
}
.min-width-400 {
  min-width: 400px;
}
</style>
